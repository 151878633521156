import React from "react";
import {
  Nav,
  PaginationClass,
  Loader,
  Button,
  Card,
  Modal,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import Warnings from "../../components/home/Warnings";
import ShowFilters from "../../components/home/ShowFilters";
import List from "../../components/home/List";

import axiosConfig from "../../utils/axiosConfig";
import handleSortClick from "../../utils/handleSortClick";

import AuthContext from "../../context/AuthContext";

import ModalContact from "../../components/general/ModalContact";

import {
  getCompanies,
  getRegions,
  getAgencies,
  getStatutes,
  getNatureExpertises,
  getWarningsTotals,
  getManageEgaLocation,
} from "../../requests/home";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // filters
      folder: "",
      sinister: "",
      client: "",
      year: "",
      status: "",
      natureExpertise: "",
      brand: "",
      model: "",
      registration: "",
      VIN: "",
      repairman: "",
      principal: "",
      company: "",
      region: "",
      regionAllianz: "",
      isClosingYear: false,
      agency: "",
      agencyAllianz: "",
      // options
      companyOptions: [],
      regionOptions: [],
      regionAllianzOptions: [
        { value: "", text: "Toutes les régions", disabled: true },
        {
          text: "ÎLE DE FRANCE",
          agencies: [
            { value: "", text: "Toutes les agences", disabled: true },
            { value: "47", text: "LANG SAINT MAUR DES FOSSES" },
            { value: "92", text: "LANG PONTOISE" },
          ],
        },
        {
          text: "NORD EST",
          agencies: [
            { value: "", text: "Toutes les agences", disabled: true },
            { value: "57", text: "LANG SAINT QUENTIN" },
            { value: "56", text: "LANG VALENCIENNES" },
            { value: "54", text: "LANG BEAUVAIS" },
            { value: "53", text: "LANG CALAIS" },
            { value: "52", text: "LANG ACHICOURT" },
            { value: "106", text: "LANG STRASBOURG" },
            { value: "107", text: "LANG COLMAR" },
          ],
        },
        {
          text: "SUD OUEST",
          agencies: [
            { value: "", text: "Toutes les agences", disabled: true },
            { value: "9", text: "LANG AURILLAC" },
            { value: "20;13", text: "LANG CHAMPNIERS" },
            { value: "100", text: "LANG GUERET" },
            { value: "1", text: "LANG TOULOUSE" },
            { value: "5;116", text: "LANG BORDEAUX" },
            { value: "4", text: "LANG MONT DE MARSAN" },
            { value: "23", text: "LANG SAINT ETIENNE" },
            { value: "22", text: "LANG CLERMONT-FERRAND" },
            { value: "2;3", text: "LANG ANGLET" },
            { value: "15", text: "LANG TARBES" },
            { value: "63", text: "LANG LYON" },
          ],
        },
      ],
      agencyOptions: [],
      statusOptions: [],
      natureExpertiseOptions: [],
      yearOptions: [],
      // pagination
      activePage: 1,
      tabLength: 20,
      total: -1,
      maxTotal: -1,
      data: [],
      order: null,
      isLoad: true,
      // warnings
      dataWarnings: [],
      isEGA: false,
      codeEGA: "",
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e, isChange = false) => {
    const { name, value } = e.target;

    this.setState(
      {
        [name]: value,
        agency:
          name === "region"
            ? ""
            : name === "agency"
            ? value
            : this.state.agency,
        agencyAllianz:
          name === "regionAllianz"
            ? ""
            : name === "agencyAllianz"
            ? value
            : this.state.agencyAllianz,
      },
      () => {
        if (isChange) {
          this.handleValidateChange();
        }
      }
    );
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState(
      {
        [name]: checked,
      },
      () => {
        this.handleValidateChange();
      }
    );
  };

  handleValidateChange = (e) => {
    if (this.state.oldDate !== JSON.stringify(this.getData())) {
      this.setState({ oldDate: JSON.stringify(this.getData()) }, () => {
        this.initSearch();
        if (this.context.source !== "client") {
          this.getWarningsTotals();
        }
      });
    }
  };

  componentDidMount() {
    if (this.props.location.state) {
      this.setState(this.props.location.state);
    } else {
      this.getCompanies();
      if (this.context.source === "agency") {
        this.getRegions();
        this.getAgencies();
        this.getNatureExpertises();
      } else if (this.context.source !== "client") {
        this.getAgencies();
        this.getNatureExpertises();
      }
      this.getStatutes();
      this.getYears();

      this.initSearch();

      if (this.context.source === "garage") {
        this.getManageEgaLocation();
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.region !== this.state.region &&
      this.context.source === "agency"
    ) {
      this.getAgencies();
    }

    this.PaginationClass = new PaginationClass(
      "home/count",
      "home/pagination",
      this.state.tabLength
    );
  }

  // START - GET OPTIONS

  getCompanies = () => {
    getCompanies((result) => {
      if (result.success) {
        this.setState({
          companyOptions: [
            { value: "", text: "Toutes les compagnies" },
            ...result.data,
          ],
        });
      } else {
        this.setState({ companyOptions: [] });
      }
    });
  };

  getRegions = () => {
    getRegions((result) => {
      if (result.success) {
        this.setState({
          regionOptions: [
            { value: "", text: "Toutes les régions" },
            ...result.data,
          ],
        });
      } else {
        this.setState({ regionOptions: [] });
      }
    });
  };

  getAgencies = () => {
    getAgencies({ region: this.state.region }, (result) => {
      if (result.success) {
        this.setState({
          agencyOptions: [
            { value: "", text: "Tous les cabinets" },
            ...result.data,
          ],
        });
      } else {
        this.setState({ agencyOptions: [] });
      }
    });
  };

  getStatutes = () => {
    getStatutes((result) => {
      if (result.success) {
        this.setState(
          {
            statusOptions: [
              { value: "", text: "Tous les états" },
              ...result.data,
            ],
          },
          () => {
            if (this.context.source !== "client") {
              this.getWarningsTotals();
            }
          }
        );
      } else {
        this.setState({ statusOptions: [] });
      }
    });
  };

  getNatureExpertises = () => {
    getNatureExpertises((result) => {
      if (result.success) {
        this.setState({
          natureExpertiseOptions: [
            { value: "", text: "Toutes les natures d'expertise" },
            ...result.data,
          ],
        });
      } else {
        this.setState({ natureExpertiseOptions: [] });
      }
    });
  };

  getYears = () => {
    let years = [];
    for (let i = new Date().getFullYear(); i >= 2018; i--) {
      years.push({ value: i, text: i });
    }

    this.setState({
      yearOptions: [{ value: "", text: "Toutes les années" }, ...years],
    });
  };

  // END - GET OPTIONS

  // START - PAGINATION

  initSearch = () => {
    this.setState({ isLoad: true, activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success) {
          this.setState(
            {
              total: result.total,
              maxTotal:
                this.state.maxTotal < result.total
                  ? result.total
                  : this.state.maxTotal,
            },
            () => {}
          );
        } else {
          this.setState({ total: 0 }, () => {
            this.setData({ success: false });
          });
        }
      });

      this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
        this.setData(result);
      });
    });
  };

  handlePageChange = (activePage) => {
    this.setState({ isLoad: true, activePage }, () => {
      this.PaginationClass.page(activePage, () =>
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success) {
            this.setData(result);
          } else {
            this.setData({ success: false });
          }
        })
      );
    });
  };

  setData = (result) => {
    if (result.success) {
      this.setState({ data: result.tabData, isLoad: false }, () => {
        if (this.context.source === "client" && this.state.data.length === 1) {
          this.props.history.push(
            `/folder/${this.state.data[0].token}`,
            this.props.state
          );
        }
      });
    } else {
      this.setState({ data: [], isLoad: false });
    }
  };

  getData = () => {
    let regionAllianz = [];
    let agencyAllianz = null;

    if (this.state.regionAllianz !== "") {
      this.state.regionAllianzOptions[
        this.state.regionAllianz
      ].agencies.forEach((agency) => {
        if (agency.value) {
          regionAllianz = regionAllianz.concat(agency.value.split(","));
        }
      });
      regionAllianz.forEach((value, idx) => {
        regionAllianz[idx] = parseInt(value);
      });
    } else {
      regionAllianz = null;
    }

    if (this.state.agencyAllianz !== "") {
      agencyAllianz = this.state.agencyAllianz.split(";");
      agencyAllianz.forEach((value, idx) => {
        agencyAllianz[idx] = parseInt(value);
      });
    }

    return {
      folder: this.state.folder,
      sinister: this.state.sinister,
      client: this.state.client,
      year: this.context.source === "client" ? null : this.state.year,
      status: this.state.status,
      natureExpertise: this.state.natureExpertise,
      brand: this.state.brand,
      model: this.state.model,
      registration: this.state.registration,
      VIN: this.state.VIN,
      principal: this.state.principal,
      repairman: this.state.repairman,
      company: this.state.company,
      region: this.state.region,
      regionAllianz: regionAllianz,
      isClosingYear: this.state.isClosingYear,
      agency: this.state.agency,
      agencyAllianz: agencyAllianz,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
      isEGA: this.state.isEGA,
    };
  };

  handleSortClick = (desc, column, sortRef) => {
    this.setState(
      { order: handleSortClick(sortRef, desc, column) },
      this.initSearch
    );
  };

  // END - PAGINATION

  // START - WARNINGS

  getWarningsTotals = () => {
    let dataWarnings = [
      {
        id: "Attente facture",
        title: "Factures à émettre",
        value: null,
        count: null,
      },
      {
        id: "Attente RDV expertise",
        title: "RDV d'expertise à prendre",
        value: null,
        count: null,
      },
      {
        id: "Attente RDV réparation",
        title: "RDV réparation à prendre",
        value: null,
        count: null,
      },
      {
        id: "Attente EAD",
        title: "EAD à envoyer",
        value: null,
        count: null,
      },
      {
        id: "En cours de traitement",
        title: "En cours de traitement par l'expert",
        value: null,
        count: null,
      },
      {
        id: "Attente éléments",
        title: "Dont l'expert attend des éléments",
        value: null,
        count: null,
      },
    ];

    const ids = this.state.statusOptions.map((element) => element.text);
    let statusArr = [];
    dataWarnings.forEach((element) => {
      const idx = ids.indexOf(element.id);
      if (idx) {
        element.value = this.state.statusOptions[idx].value;
        statusArr.push(element.value);
      }
    });

    getWarningsTotals(
      {
        statusArr,
        natureExpertise: this.state.natureExpertise,
        folder: this.state.folder,
        sinister: this.state.sinister,
        client: this.state.client,
        year: this.context.source === "client" ? null : this.state.year,
        brand: this.state.brand,
        model: this.state.model,
        registration: this.state.registration,
        VIN: this.state.VIN,
        principal: this.state.principal,
        repairman: this.state.repairman,
        company: this.state.company,
        region: this.state.region,
        agency: this.state.agency,
        isClosingYear: this.state.isClosingYear,
      },
      (result) => {
        if (result.success) {
          result.data.forEach((element, idx) => {
            dataWarnings[idx].count = element;
          });
          this.setState({ dataWarnings });
        } else {
          this.setState({ dataWarnings: [] });
        }
      }
    );
  };

  // END - WARNINGS

  getManageEgaLocation = () => {
    getManageEgaLocation((result) => {
      if (result.success) {
        this.setState({
          codeEGA: result.data,
        });
      }
    });
  };

  render() {
    const source =
      this.context.source === "principal"
        ? "- Mandant"
        : this.context.source === "company"
        ? "- Compagnie"
        : this.context.source === "garage"
        ? "- Réparateur"
        : this.context.source === "client"
        ? "- Assuré"
        : "";

    return (
      <>
        <Nav title={`Tableau de bord ${source}`} className="mb-10 mb-20-xl" />

        {this.state.codeEGA ? (
          <Card title="Dossiers EGA" className="mb-20">
            <p>
              Des dossiers EGA sont en attentes de prise de RDV, cliquez sur le
              bouton ci-dessous pour visualier la liste des dossiers et
              effectuer la prise de RDV
            </p>
            <Button
              className="w-auto"
              text={
                this.state.isEGA
                  ? "fermer le tableau de bord EGA"
                  : "Accéder au tableau de bord EGA"
              }
              orange
              onClick={() =>
                this.setState({ isEGA: !this.state.isEGA }, () =>
                  this.initSearch()
                )
              }
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </Card>
        ) : null}
        {this.context.source !== "client" ? (
          <>
            {!this.state.isEGA ? (
              <Warnings
                dataWarnings={this.state.dataWarnings}
                handleInputChange={this.handleInputChange}
              />
            ) : null}
          </>
        ) : null}
        {this.context.source !== "client" || this.state.maxTotal > 4 ? (
          <ShowFilters
            folder={this.state.folder}
            sinister={this.state.sinister}
            client={this.state.client}
            year={this.state.year}
            status={this.state.status}
            natureExpertise={this.state.natureExpertise}
            brand={this.state.brand}
            model={this.state.model}
            registration={this.state.registration}
            VIN={this.state.VIN}
            repairman={this.state.repairman}
            principal={this.state.principal}
            company={this.state.company}
            region={this.state.region}
            regionAllianz={this.state.regionAllianz}
            agency={this.state.agency}
            agencyAllianz={this.state.agencyAllianz}
            companyOptions={this.state.companyOptions}
            regionOptions={this.state.regionOptions}
            regionAllianzOptions={this.state.regionAllianzOptions}
            isClosingYear={this.state.isClosingYear}
            agencyOptions={this.state.agencyOptions}
            statusOptions={this.state.statusOptions}
            natureExpertiseOptions={this.state.natureExpertiseOptions}
            yearOptions={this.state.yearOptions}
            handleInputChange={this.handleInputChange}
            handleCheckboxChange={this.handleCheckboxChange}
            handleValidateChange={this.handleValidateChange}
            total={this.state.total}
          />
        ) : null}
        <List
          {...this.props}
          handlePageChange={this.handlePageChange}
          handleSortClick={this.handleSortClick}
          activePage={this.state.activePage}
          tabLength={this.state.tabLength}
          total={this.state.total}
          data={this.state.data}
          order={this.state.order}
          state={this.state}
          onClick={
            this.state.isEGA
              ? (folder) => {
                  window.open(
                    `https://ega.pilotagecabinet.fr/${folder.id}/${this.state.codeEGA}`,
                    "_blank"
                  );
                }
              : null
          }
        />

        <Loader show={this.state.isLoad} />
        <ModalContact />
      </>
    );
  }
}
